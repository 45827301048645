import { loadView } from "@/router/loadView"

export default [
    //#region 【1】 (收成月報表)
    {
        path: '/ReportManagement/havest-month-report',
        name: 'HarvestMonthView',
        component: loadView("ReportManagement/HarvestMonthView"),
        meta: { roles: ['admin', 'accountant'], keepAlive: true } // 需要為 Admin
    },
    //#endregion

    //#region 【2】 (2023年報-排程收成)
    {
        path: '/ReportManagement/schedule-history/:year',
        name: 'ScheduleHistoryYear',
        component: loadView("ReportManagement/ScheduleHistoryYear"),
        meta: { roles: ['admin'], keepAlive: true } // 需要為 Admin
    },
    //#endregion

    //#region 【3】 (2023年報-收成報表)
    {
        path: '/ReportManagement/harvest-history/:year',
        name: 'HarvestHistoryYearView',
        component: loadView("ReportManagement/HarvestHistoryYearView"),
        meta: { roles: ['admin', 'accountant'], keepAlive: true } // 需要為 Admin
    },

    //#endregion


    //#region 【3】 (歷史年報表)
    { // 年份列表
        path: '/ReportManagement/history-report',
        name: 'HistoryYearList',
        component: loadView("ReportManagement/HistoryYearList"),
        meta: { roles: ['admin', 'accountant', 'marketing']} // 需要為 Admin
    },

    { // 歷史收成年報
        path: '/ReportManagement/history-report/harvest/:year',
        name: 'HarvestHistoryYearReport',
        component: loadView("ReportManagement/HistoryYearList/HarvestHistoryYearReport"),
        meta: { roles: ['admin', 'accountant', 'marketing']}, // 需要為 Admin
        redirect: to => {
            // 只提供 category 的預設值，保留用戶提供的 year 參數
            return { 
                name: 'HarvestHistoryYearReportView', 
                params: { 
                    year: to.params.year,  // 保留原始的 year 參數
                    category: 'all'  // 只設定 category 的預設值
                } 
            }
        },
        children: [
            {
                path: 'view/:category',
                name: 'HarvestHistoryYearReportView',
                component: loadView("ReportManagement/HistoryYearList/HarvestHistoryYearReport/HarvestHistoryYearReportView"),
                meta: { roles: ['admin', 'accountant', 'marketing']}, // 需要為 Admin
            }
        ]
    },

    { // 歷史排成收成年報
        path: '/ReportManagement/history-report/schedule/:year',
        name: 'ScheduleHistoryYearReport',
        component: loadView("ReportManagement/HistoryYearList/ScheduleHistoryYearReport"),
        meta: { roles: ['admin', 'accountant', 'marketing']} // 需要為 Admin
    },

    { // 歷史收成成長年報
        path: '/ReportManagement/history-report/sell-grow',
        name: 'SellGrowHistoryYearReport',
        component: loadView("ReportManagement/HistoryYearList/SellGrowHistoryYearReport"),
        meta: { roles: ['admin', 'accountant', 'marketing']}, // 需要為 Admin
        redirect: { name: 'SellGrowHistoryYearReportForAll' },
        children: [
            {
                path: 'all',
                name: 'SellGrowHistoryYearReportForAll',
                component: loadView("ReportManagement/HistoryYearList/SellGrowHistoryYearReport/SellGrowHistoryYearReportForAll"),
                meta: { roles: ['admin', 'accountant', 'marketing']}, // 需要為 Admin
            },
            {
                path: 'company',
                name: 'SellGrowHistoryYearReportForCompany',
                component: loadView("ReportManagement/HistoryYearList/SellGrowHistoryYearReport/SellGrowHistoryYearReportForCompany"),
                meta: { roles: ['admin', 'accountant', 'marketing']}, // 需要為 Admin
            },
            {
                path: 'bank',
                name: 'SellGrowHistoryYearReportForBank',
                component: loadView("ReportManagement/HistoryYearList/SellGrowHistoryYearReport/SellGrowHistoryYearReportForBank"),
                meta: { roles: ['admin', 'accountant', 'marketing']}, // 需要為 Admin
            },
        ]

    },
    //#endregion

    //#region 【4】 (獎金系統)
    {
        path: '/bonus-analysis',
        name: 'ScheduleBonusAnalysis',
        component: loadView("ReportManagement/ScheduleBonusAnalysis"),
        meta: { roles: ['admin', 'accountant'], keepAlive: true } // 需要為 Admin
    },
    //#endregion

    //#region 【5】 (總目標管理)
    {
        path: '/total-annual-kpi',
        name: 'TotalAnnualKPIManagement',
        component: loadView("ReportManagement/TotalAnnualKPIManagement"),
        meta: { roles: ['admin'], keepAlive: false } // 需要為 Admin
    },
    //#endregion
];