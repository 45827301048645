import BaseService from "@/api/services/BaseService"
import service from '@/api/service';

class LineLoginService extends BaseService{
    // 獲取 LINE 登入 URL
    get_login_url(){
        return service.get('auth/line/login/url/?mode=login')
    }

    // 獲取 LINE 綁定 URL
    get_binding_url(){
        return service.get('auth/line/login/url/?mode=binding')
    }
   
    // 處理 callback
    handle_callback(code){
        return service.post('auth/line/login/callback/', { code })
    }

    exchange_temp_token(data){
        return service.post('auth/line/login/exchange-temp-token/', data)
    }

    unbind_account(){
        return service.post('auth/line/login/unbind-account/')
    }
}


// 將封裝的 class匯出
export default new LineLoginService()