// 先引用 BaseServer取得 CRUD封裝
import BaseService from "@/api/services/BaseService"
import service from '@/api/service';
class UserService extends BaseService{
    constructor(){
        super('system/user') // 帶入 order為 resource
    }

    // 取得當前使用者資訊
    get_current_user(){
        return service.get('system/user/current/')
    }

    // 修改當前使用者資訊
    update_current_user(data){
        return service.patch('system/user/current/', data)
    }

    // 修改密碼
    change_password(data){
        return service.post('system/user/change-password/', data)
    }   
}


// 將封裝的 class匯出
export default new UserService()