import { loadView } from "@/router/loadView"

export default [

    { // 公司別
        path: '/location/company',
        name: 'CompanyManagement',
        meta: { roles: ['admin'] }, 
        component: loadView("Location/CompanyManagement"),
    },

    { // 銀行
        path: '/location/bank',
        name: 'BankManagement',
        meta: { roles: ['admin'] }, 
        component: loadView("Location/BankManagement"),
    },

    { // 迴路
        path: '/location/circuit',
        name: 'CircuitManagement',
        meta: { roles: ['admin'] }, 
        component: loadView("Location/CircuitManagement"),
    },
    
    { // 地區別
        path: '/location/area',
        name: 'AreaManagement',
        meta: { roles: ['admin'] }, 
        component: loadView("Location/AreaManagement"),
    },
    
    { // 案場
        path: '/location/position',
        name: 'PositionManagement',
        meta: { roles: ['admin', 'PT'] }, 
        component: loadView("Location/PositionManagement"),
    },
    
];