import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'


/* SweetAlert */
import VueSweetalert2 from 'vue-sweetalert2';
// If you don't need the styles, do not connect
import 'sweetalert2/dist/sweetalert2.min.css';
Vue.use(VueSweetalert2);

// 【material-design-icon】
// npm install --save material-design-icons-iconfont
import 'material-design-icons-iconfont/dist/material-design-icons.css'

// 【ScrollTo】
const VueScrollTo = require('vue-scrollto');
Vue.use(VueScrollTo);

// 【vue-cookies】
const VueCookie = require('vue-cookies')
Vue.use(VueCookie)

// 【math.js】
// import math from 'mathjs'
// Vue.use(math)

// API呼叫用
import axios from 'axios'
// import axios from '@/plugins/axios.js'
import VueAxios from 'vue-axios'

axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';
Vue.use(VueAxios, axios)

axios.defaults.baseURL = `/`
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.withCredentials = true
// axios.defaults.baseURL = (process.env.API_PATH !== 'production') ? '/api' : '';


// 阻止啟動生產消息，常用作指令
Vue.config.productionTip = process.env.VUE_APP_ProductionTip == 'true' || false

// 開啟 DevTools
Vue.config.devtools = process.env.VUE_APP_DevTools == 'true' || false 


Vue.prototype.$axios = axios

// 【VueExpandableImage】
import VueExpandableImage from 'vue-expandable-image'
Vue.use(VueExpandableImage)

/* 【全域 Mixins】 */
import {globalMixins} from "@/mixins/globalMixins.js"
Vue.mixin(globalMixins)


//#region (Directives - 自定義指令)
import directives from './directives'; // 自定義指令
Vue.use(directives)
//#endregion

//region [Vue-Treeselect]
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
Vue.component('Treeselect', Treeselect)
// #endregion

//#region [vue-konva]
import VueKonva from 'vue-konva'
Vue.use(VueKonva)
//#endregion

new Vue({
  router,
  store,
  vuetify,
  axios,
  render: h => h(App)
}).$mount('#app')
