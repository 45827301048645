import Swal from 'sweetalert2';
import store from "@/store/"


// [錯誤訊息的回傳]
//   {
//     "error": {
//         "name": [
//             "該角色代號已經存在!"
//         ],
//         "name_zh": [
//             "該角色名稱已經存在!"
//         ]
//     },
//     "status_code": 400
// }
// 整理錯誤訊息的顯示方式
export function extractErrorMessage(errorData) {
  // 先確認有無 error關鍵attr
  if (errorData.error) {

    // 如果該錯誤訊息為字串 => 直接回傳
    if (typeof errorData.error === 'string') {
      return errorData.error;
    } 
    
    // 處理多欄位錯誤訊息(通常情況為陣列)
    else {
      // 這邊會將所有錯誤訊息欄位的內容整理起來
      // ["該角色代號已經存在!", "該角色名稱已經存在!"]
      const errors = Object.values(errorData.error).flat()

      // 假設已經是字串 => 直接回傳
      if(typeof errors[0] === 'string') {
        return errors[0] // 通常就顯示第一個就好了
      }
      // 假設是物件 => 再進一步解析處理 (這可能是批次所造成)
      else if(typeof errors[0] === 'object') {
        return Object.values(errors[0]).flat()[0]
      }
      
    }
  }
  return '發生未知錯誤，請稍後再試。';
}


export async function errorHandle(status, error) {
  if (error.response) {
    // 取得該回應的method
    const method = error.response.config.method
    const method_hash_map_title = { // 用於顯示的文字
      "get": '查詢',
      "post": '新增',
      "put": '更新',
      "patch": '更新',
      "delete": '刪除'
    }

    // 取得該method的中文名稱
    const method_title = method_hash_map_title[method]

    // 取得該錯誤的標題
    const title = `${method_title}發生錯誤`
    
    // 取得該回應的data
    const response_data = error.response?.data

    // 取得該回應的status code
    const status = error.response.status



    switch (status) {
      // Internal Server Error
      case 400:
        const error_tag = "客戶端操作發生錯誤 (代號: 400)"
        if (response_data) {
          Swal.fire({
              title: title, 
              text: extractErrorMessage(response_data), 
              icon: "error",
              confirmButtonText: "確認",
              footer: `${error_tag}`,
          })
        }

        break;

      // Unauthorized. Not pass authentication.
      case 401:
        if (response_data) {
          console.log(response_data);
          
          const error_status_title = "身分未被授權"
          const error_message = extractErrorMessage(response_data)
          Swal.fire({
              title: title, 
              html: `<strong>【錯誤問題】：${error_status_title}</strong><br><br>${error_message}`, 
              icon: "error",
              confirmButtonText: "確認",
              footer: '<a href="/login">請重新登入</a>'
          })
        }

        else{ // 其他權限問題 (access token過期)
          store.dispatch("auth/handleToken")
        }

        break;
  
      // Forbidden. Not pass Authorization.
      case 403:
        if (response_data) {
          const error_status_title = "權限不足禁止訪問"
          const error_message = extractErrorMessage(response_data)
          Swal.fire({
              title: title, 
              html: `<strong>【錯誤問題】：${error_status_title}</strong><br><br>${error_message}`, 
              icon: "error",
              confirmButtonText: "確認",
              footer: '請確認您的權限是否足夠'

          })
        }
        console.log('無使用權限');
        break;
  
      // Not Found
      case 404:
        if (response_data) {
          const error_status_title = "找不到資源"
          const error_message = extractErrorMessage(response_data)
          Swal.fire({
              title: title, 
              html: `<strong>【錯誤問題】：${error_status_title}</strong><br><br>${error_message}`, 
              icon: "error",
              confirmButtonText: "確認",
              footer: '請確認您的網址是否正確'
          })
        }
        console.log('資源不存在');
        break;
  
      // Unprocessable Entity. Invalid input.
      case 422:
        if (response_data) {
          const error_status_title = "檢查欄位資訊"
          const error_message = extractErrorMessage(response_data)
          Swal.fire({
              title: title, 
              html: `<strong>【錯誤問題】：${error_status_title}</strong><br><br>${error_message}`, 
              icon: "error",
              confirmButtonText: "確認",
              footer: '請確認您的網址是否正確'
          })
        }
        console.log('檢查欄位資訊');
        break;
  
      // Internal Server Error
      case 500:
        if (response_data) {
          const error_status_title = "伺服器發生了點問題"
          const error_message = extractErrorMessage(response_data)
          Swal.fire({
              title: title, 
              html: `<strong>【錯誤問題】：${error_status_title}</strong><br><br>${error_message}`, 
              icon: "error",
              confirmButtonText: "確認",
              footer: '請聯絡開發人員!'
          })
        }
        console.log(error);
        break;
  
      default:
        console.error(error);
    }
  }
}