import axios from 'axios'
import store from "@/store/"
import { errorHandle } from './errorHandle';
import Swal from 'sweetalert2';
import { jwtDecode } from "jwt-decode";

//https://pingfed.gitlab.io/ping-blog/2020/04/12/vue/vue_apiWrap/


// JWT 檢查是否過期
function isTokenExpired(token) {
  try {
    const decoded = jwtDecode(token);
    const currentTime = Date.now() / 1000;
    return decoded.exp < currentTime;
  } catch (e) {
    return true;
  }
}


const service = axios.create({
    // baseURL: util.baseURL(),
    baseURL: "/api",
    withCredentials: true,
    // timeout: 20000, // 請求時間超過 20000毫秒(20秒)，請求會被中止

    // 序列化參數 ???
    // paramsSerializer: (params) => qs.stringify(params, { indices: false })

    // 在上傳、下載途中可執行的事情 (progressBar、Loading)
    // onUploadProgress(progressEvt) { /* 原生 ProgressEvent */  },
    // onDownloadProgress(progressEvt) { /* 原生 ProgressEvent */ },
  })

  
  // 攔截 API request 的請求
  service.interceptors.request.use(async (request)=> {
      // JWT Token
      const accessToken = localStorage.getItem('authTokenAccess') || null

      if (accessToken) {
        // 驗證該 accessToken是否過期
        if (isTokenExpired(accessToken)) {
          // [過期]: 重新取得新的accessToken (如果連 refresh都過期 => 重新登入)
          await store.dispatch("auth/handleToken");
        } 

        // 變成 Bearer TOKEN
        const TOKEN = "Bearer " + localStorage.getItem("authTokenAccess")
  
        // API送出前可以做最後的處理
        request.headers['Authorization'] = TOKEN;
      }
      return request;
  }, error=> {
      // 如果送出前失敗了，這邊就可以做一些處理
      return Promise.reject(error);
  });


  // 攔截 API response 的回傳
  service.interceptors.response.use(response  => {
      // 這邊可以對回來的資料先進行驗證處理，再來決定要不要把資料給吐出去
      return Promise.resolve(response);
  }, async (error) => {
      // 檢查是否是取消請求的錯誤
      if (axios.isCancel(error)) {
        // 靜默處理取消請求的錯誤
        return Promise.reject(error);
      }

      // // 這邊當API發生錯誤的時候就可以處理 Error handling
        const { response, request } = error;

        if (response) {
          // receive the response, but has error
          errorHandle(response.status, error);
          return Promise.reject(error.response.data);
        } 

        // 請求有發送成功，但沒有收到回傳
        else if (error.request) {
          console.error('No response received from the server.');
        } 
        
        // 請求未發送
        else {
          console.error('Error sending the request:', error.message);
        }

        // 請求過時或者是斷網
        if (!window.navigator.onLine) {
            console.log('There are some problems on network. Please reload again');
            const swal = await Swal.fire({
                title: "網路發生異常", 
                text: "請重新整理", 
                icon: "error",
                confirmButtonText: "重新整理"
            })
    
            if (swal.isConfirmed){
                window.location.reload();
            }
        } 
        
        // 其他錯誤
        else {
          return Promise.reject(error);
        }

        return Promise.reject(error);
  })


export default service;