import { loadView } from "@/router/loadView"

export default [

    { // 退料資訊
        path: '/f/alert-material-out',
        name: 'AlertMaterialOutListView',
        component: loadView("Home/AlertMaterialOut/AlertMaterialOutListView"),
        meta: {
            roles: ['admin', 'keeper']
        }
    },
    { // 單一退料資訊
        path: '/f/alert-material-out/detail/:id',
        name: 'AlertMaterialOutDetailView',
        component: loadView("Home/AlertMaterialOut/AlertMaterialOutDetailView"),
        meta: {
            roles: ['admin', 'keeper']
        }
    },

    { // 調查回報資訊
        path: '/f/alert-maintenance-investigation',
        name: 'AlertMaintenanceInvestigationListView',
        component: loadView("Home/AlertMaintenanceInvestigation/AlertMaintenanceInvestigationListView"),
        meta: {
            roles: ['admin', 'manager', 'team_leader', 'editor', 'proxy']
        }
    },
    { // 單一調查回報資訊
        path: '/f/alert-maintenance-investigation/detail/:id',
        name: 'AlertMaintenanceInvestigationDetailView',
        component: loadView("Home/AlertMaintenanceInvestigation/AlertMaintenanceInvestigationDetailView"),
        meta: {
            roles: ['admin', 'manager', 'team_leader', 'editor', 'proxy']
        }
    },
    { // 單一調查回報資訊(回報)
        path: '/f/alert-maintenance-investigation/detail/:id/reply/:form_position_id',
        name: 'AlertMaintenanceInvestigationReplyView',
        component: loadView("Home/AlertMaintenanceInvestigation/AlertMaintenanceInvestigationReplyView"),
        meta: {
            roles: ['admin', 'manager', 'team_leader', 'editor', 'proxy']
        }
    },

    
];