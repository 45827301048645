<template>
  <div class="loading-screen" :class="{ 'fade-out': !isActive }">
    <div class="loading-content">
      <!-- Logo (根據需要替換為您的實際Logo) -->
      <div class="logo-container mb-8">
        <img v-if="logo" :src="logo" alt="Logo" class="logo">
        <h1 v-else class="app-name">{{ appName }}</h1>
      </div>
      
      <!-- 加載動畫 -->
      <div class="loading-animation">
        <div class="dot" v-for="i in 4" :key="i" :style="{ animationDelay: `${(i-1)*0.15}s` }"></div>
      </div>
      
      <!-- 加載訊息 -->
      <div class="loading-message mt-8" v-if="message">{{ message }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoadingScreen',
  props: {
    isActive: {
      type: Boolean,
      default: true
    },
    logo: {
      type: String,
      default: null
    },
    message: {
      type: String,
      default: '載入中...'
    },
    // 是否在完成後自動卸載組件
    autoDestroy: {
      type: Boolean,
      default: false
    }
  },
  data(){
    return {
      appName: process.env.VUE_APP_TITLE
    }
  },
  watch: {
    isActive(newVal) {
      if (!newVal && this.autoDestroy) {
        // 等動畫結束後卸載
        setTimeout(() => {
          this.$emit('destroyed');
        }, 1000);
      }
    }
  }
}
</script>

<style scoped>
.loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, #f8f9fa 0%, #e9ecef 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  transition: opacity 0.5s ease, visibility 0.5s ease;
  opacity: 1;
  visibility: visible;
}

.loading-screen.fade-out {
  opacity: 0;
  visibility: hidden;
}

.loading-content {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logo-container {
  animation: pulse 2s infinite ease-in-out;
}

.logo {
  max-width: 120px;
  max-height: 120px;
}

.app-name {
  font-size: 2.5rem;
  font-weight: 700;
  color: #333;
  margin: 0;
  letter-spacing: -0.5px;
}

.loading-animation {
  display: flex;
  justify-content: center;
  gap: 8px;
}

.dot {
  width: 12px;
  height: 12px;
  background-color: #4f46e5; /* 靛青色，可換成您的品牌色 */
  border-radius: 50%;
  animation: bounce 1.4s infinite ease-in-out both;
}

.loading-message {
  font-size: 1rem;
  color: #6c757d;
  font-weight: 500;
}

@keyframes bounce {
  0%, 80%, 100% {
    transform: scale(0);
    opacity: 0.2;
  }
  40% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

/* 深色模式支援 */
@media (prefers-color-scheme: dark) {
  .loading-screen {
    background: linear-gradient(135deg, #212529 0%, #343a40 100%);
  }
  
  .app-name {
    color: #f8f9fa;
  }
  
  .loading-message {
    color: #dee2e6;
  }
}
</style>