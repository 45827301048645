
const getDefaultState = () => { // state
    return {
        filterDate: [],
        filterStatus: 'all',
        filterPriority: null,
    }
}

export default {
    namespaced: true,
    state: getDefaultState(),
    getters: {
     
    },

    mutations: { 
        setFilterDate(state, payload){
            state.filterDate = payload
        },
        setFilterStatus(state, payload){
            state.filterStatus = payload
        },
        setFilterPriority(state, payload){
            state.filterPriority = payload
        },
    
      
    },
    
    actions: {
        setFilterDate({ commit }, value) { // 更新是否需要刷新
            commit('setFilterDate', value);
        },
        setFilterStatus({ commit }, value) { // 更新是否需要刷新
            commit('setFilterStatus', value);
        },
        setFilterPriority({ commit }, value) { // 更新是否需要刷新
            commit('setFilterPriority', value);
        },
    }

}