import { loadView } from "@/router/loadView"
export default [
    {
        path: '/maintenance/management',
        name: 'MaintenanceManagementView',
        component: loadView("MaintenanceManagement/MaintenanceManagementView"),
        meta: { roles: ['tester', 'admin', 'manager', 'maintenance_assigner', 'maintenance_officer', 'maintenance_receiver', 'maintenance_technician'], title: "維運管理"}, // 需要為 Admin
        children: [
            //#region (訂單管理)
            { // 訂單管理
                path: 'order', 
                name: 'OrderManagementView', 
                meta: { is_entrance: true, roles: ['tester', 'admin', 'manager', 'maintenance_assigner', 'maintenance_officer', 'maintenance_receiver', 'maintenance_technician'] , title: "訂單管理", icon: "mdi-tools"}, 
                component: loadView("MaintenanceManagement/MaintenanceManagementView/OrderManagementView"),
            },

            // is_entrance: 是否為入口
            //#region (待收訂單)
            { // 待收訂單
                path: 'pending', 
                name: 'PendingOrderView', 
                meta: { is_entrance: false, roles: ['tester', 'admin', 'manager', 'maintenance_assigner', 'maintenance_officer', 'maintenance_receiver', 'maintenance_technician'] , title: "訂單管理 / 待收訂單", icon: "mdi-tools"}, 
                component: loadView("MaintenanceManagement/MaintenanceManagementView/OrderManagementView/PendingOrderView"),
            },
            { // 待收訂單 / 訂單資訊
                path: 'pending/:id', 
                name: 'PendingOrderDetailView', 
                meta: { is_entrance: false, roles: ['tester', 'admin', 'manager', 'maintenance_assigner', 'maintenance_officer', 'maintenance_receiver', 'maintenance_technician'] , title: "訂單管理 / 待收訂單 / 訂單資訊", icon: "mdi-tools"}, 
                component: loadView("MaintenanceManagement/MaintenanceManagementView/OrderManagementView/PendingOrderView/PendingOrderDetailView"),
            },
            //#endregion

            //#region (訂單進度)
            { // 訂單進度
                path: 'progress', 
                name: 'ProgressOrderView', 
                meta: { is_entrance: false, roles: ['tester', 'admin', 'manager', 'maintenance_assigner', 'maintenance_officer', 'maintenance_receiver', 'maintenance_technician'] , title: "訂單管理 / 訂單進度", icon: "mdi-calendar-clock-outline"}, 
                component: loadView("MaintenanceManagement/MaintenanceManagementView/OrderManagementView/ProgressOrderView"),
            },
            { // 訂單進度 / 訂單資訊
                path: 'progress/:id', 
                name: 'ProgressOrderDetailView', 
                meta: { is_entrance: false, roles: ['tester', 'admin', 'manager', 'maintenance_assigner', 'maintenance_officer', 'maintenance_receiver', 'maintenance_technician'] , title: "訂單管理 / 訂單進度 / 訂單資訊", icon: "mdi-calendar-clock-outline"}, 
                component: loadView("MaintenanceManagement/MaintenanceManagementView/OrderManagementView/ProgressOrderView/ProgressOrderDetailView"),
            },
            //#endregion

            //#region (歷史訂單)
            { // 歷史訂單
                path: 'history', 
                name: 'HistoryOrderView', 
                meta: { is_entrance: false, roles: ['tester', 'admin', 'manager', 'maintenance_assigner', 'maintenance_officer', 'maintenance_receiver', 'maintenance_technician'] , title: "訂單管理 / 歷史訂單", icon: "mdi-calendar-check-outline"}, 
                component: loadView("MaintenanceManagement/MaintenanceManagementView/OrderManagementView/HistoryOrderView"),
            },

            { // 歷史訂單 / 訂單明細
                path: 'history/:id', 
                name: 'HistoryOrderDetailView', 
                meta: { is_entrance: false, roles: ['tester', 'admin', 'manager', 'maintenance_assigner', 'maintenance_officer', 'maintenance_receiver', 'maintenance_technician'] , title: "訂單管理 / 歷史訂單 / 訂單明細", icon: "mdi-calendar-check-outline"}, 
                component: loadView("MaintenanceManagement/MaintenanceManagementView/OrderManagementView/HistoryOrderView/HistoryOrderDetailView"),
            },
            //#endregion

            //#region (備料訂單)
            { // 備料訂單
                path: 'wait-material', 
                name: 'WaitMaterialOrderView', 
                meta: { is_entrance: false, roles: ['tester', 'admin', 'manager', 'maintenance_assigner', 'maintenance_officer', 'maintenance_receiver', 'maintenance_technician'] , title: "訂單管理 / 備料訂單", icon: "mdi-calendar-check-outline"}, 
                component: loadView("MaintenanceManagement/MaintenanceManagementView/OrderManagementView/WaitMaterialOrderView"),
            },
            
            //#endregion

            //#region (驗收訂單)
            { // 驗收訂單
                path: 'wait-check', 
                name: 'WaitCheckOrderView', 
                meta: { is_entrance: false, roles: ['tester', 'admin', 'manager', 'maintenance_assigner', 'maintenance_officer', 'maintenance_receiver', 'maintenance_technician'] , title: "訂單管理 / 驗收訂單", icon: "mdi-calendar-check-outline"}, 
                component: loadView("MaintenanceManagement/MaintenanceManagementView/OrderManagementView/WaitCheckOrderView"),
            },

            { // 驗收訂單 / 訂單明細
                path: 'wait-check/:id', 
                name: 'WaitCheckOrderDetailView', 
                meta: { is_entrance: false, roles: ['tester', 'admin', 'manager', 'maintenance_assigner', 'maintenance_officer', 'maintenance_receiver', 'maintenance_technician'] , title: "訂單管理 / 驗收訂單 / 訂單明細", icon: "mdi-calendar-check-outline"}, 
                component: loadView("MaintenanceManagement/MaintenanceManagementView/OrderManagementView/WaitCheckOrderView/WaitCheckOrderDetailView"),
            },
            //#endregion


            //#endregion

            
            // { // 後續追蹤
            //     path: 'tracking', 
            //     name: 'FollowUpTrackingView', 
            //     meta: { is_entrance: true, roles: ['tester', 'admin', 'manager', 'maintenance_assigner', 'maintenance_receiver', 'maintenance_technician'] , title: "後續追蹤", icon: "mdi-link"}, 
            //     component: loadView("MaintenanceManagement/MaintenanceManagementView/FollowUpTrackingView"),
            // },



            //#region (叫修補單)
            { // 叫修補單
                path: 'supplement', 
                name: 'SupplementManagementView', 
                meta: { is_entrance: true, roles: ['tester', 'admin', 'manager', 'maintenance_assigner', 'maintenance_officer', 'maintenance_receiver', 'maintenance_technician'] , title: "叫修補單", icon: "mdi-briefcase-plus"}, 
                component: loadView("MaintenanceManagement/MaintenanceManagementView/SupplementManagementView"),
            },
            { // 叫修補單
                path: 'supplement/create/:id', 
                name: 'SupplementManagementForCreate', 
                meta: { is_entrance: false, roles: ['tester', 'admin', 'manager', 'maintenance_assigner', 'maintenance_officer', 'maintenance_receiver', 'maintenance_technician'] , title: "叫修補單 / 建立訂單", icon: "mdi-briefcase-plus"}, 
                component: loadView("MaintenanceManagement/MaintenanceManagementView/SupplementManagementView/SupplementManagementForCreate"),
            },
            // { // 叫修補單 (詳細)
            //     path: 'Supplement/detail/:id', 
            //     name: 'SupplementDetailView', 
            //     meta: { is_entrance: false, roles: ['tester', 'admin', 'manager', 'maintenance_assigner', 'maintenance_officer', 'maintenance_receiver'] , title: "叫修補單 / 詳情", icon: "mdi-bullhorn"}, 
            //     component: loadView("MaintenanceManagement/MaintenanceManagementView/SupplementManagementView/SupplementDetailView"),
            // },
           
            //#endregion


            //#region (調查回報)
            { // 調查回報
                path: 'investigation', 
                name: 'InvestigationManagementView', 
                meta: { is_entrance: true, roles: ['tester', 'admin', 'manager', 'maintenance_assigner', 'maintenance_officer', 'maintenance_receiver'] , title: "調查回報", icon: "mdi-bullhorn"}, 
                component: loadView("MaintenanceManagement/MaintenanceManagementView/InvestigationManagementView"),
            },
            { // 調查回報 (詳細)
                path: 'investigation/detail/:id', 
                name: 'InvestigationDetailView', 
                meta: { is_entrance: false, roles: ['tester', 'admin', 'manager', 'maintenance_assigner', 'maintenance_officer', 'maintenance_receiver'] , title: "調查回報 / 詳情", icon: "mdi-bullhorn"}, 
                component: loadView("MaintenanceManagement/MaintenanceManagementView/InvestigationManagementView/InvestigationDetailView"),
            },
           
            //#endregion


            //#region (人員管控)
            { // 人員管控
                path: 'personnel', 
                name: 'PersonnelManagementView', 
                meta: { is_entrance: true, roles: ['tester', 'admin', 'manager', 'maintenance_assigner', 'maintenance_officer', 'maintenance_receiver', 'maintenance_technician'] , title: "人員管控", icon: "mdi-account-wrench"}, 
                component: loadView("MaintenanceManagement/MaintenanceManagementView/PersonnelManagementView"),
            },
            { // 人員管控(詳細成員)
                path: 'personnel/detail/:id', 
                name: 'PersonnelDetailView', 
                meta: { is_entrance: false, roles: ['tester', 'admin', 'manager', 'maintenance_assigner', 'maintenance_officer', 'maintenance_receiver', 'maintenance_technician'] , title: "人員管控", icon: "mdi-account-wrench"}, 
                component: loadView("MaintenanceManagement/MaintenanceManagementView/PersonnelManagementView/PersonnelDetailView"),
            },
            //#endregion


            //#region (系統設定)
            { // 系統設定
                path: 'configuration', 
                name: 'ConfigurationView', 
                meta: { is_entrance: true, roles: ['tester', 'admin', 'manager', 'maintenance_assigner', 'maintenance_officer', 'maintenance_receiver', 'maintenance_technician'] , title: "系統設定", icon: "mdi-cog"}, 
                component: loadView("MaintenanceManagement/MaintenanceManagementView/ConfigurationView"),
            },
            { // 系統設定 / 進度狀態
                path: 'configuration/status', 
                name: 'ConfigureOrderStatus', 
                meta: { is_entrance: false, roles: ['tester', 'admin', 'manager', 'maintenance_assigner', 'maintenance_officer'] , title: "系統設定 / 訂單進度狀態", icon: "mdi-cog"}, 
                component: loadView("MaintenanceManagement/MaintenanceManagementView/ConfigurationView/ConfigureOrderStatus"),
            },
            { // 系統設定 / 優先序
                path: 'configuration/priority', 
                name: 'ConfigureOrderPriority', 
                meta: { is_entrance: false, roles: ['tester', 'admin', 'manager', 'maintenance_assigner', 'maintenance_officer'] , title: "系統設定 / 訂單優先序", icon: "mdi-cog"}, 
                component: loadView("MaintenanceManagement/MaintenanceManagementView/ConfigurationView/ConfigureOrderPriority"),
            },
            { // 系統設定 / 設備元件
                path: 'configuration/equipment-component', 
                name: 'ConfigureOrderEquipmentComponent', 
                meta: { 
                    keepAlive: true,
                    is_entrance: false, 
                    roles: ['tester', 'admin', 'manager', 'maintenance_assigner', 'maintenance_officer', 'maintenance_receiver', 'maintenance_technician'] , 
                    title: "系統設定 / 設備元件", 
                    icon: "mdi-cog"
                }, 
                component: loadView("MaintenanceManagement/MaintenanceManagementView/ConfigurationView/ConfigureOrderEquipmentComponent"),
            },
            { // 系統設定 / 設備元件 / 瀏覽
                path: 'configuration/equipment-component/:id/view', 
                name: 'EquipmentComponentView', 
                meta: { is_entrance: false, roles: ['tester', 'admin', 'manager', 'maintenance_assigner', 'maintenance_officer', 'maintenance_receiver', 'maintenance_technician'] , title: "系統設定 / 設備元件 / 瀏覽", icon: "mdi-cog"}, 
                component: loadView("MaintenanceManagement/MaintenanceManagementView/ConfigurationView/EquipmentComponent/EquipmentComponentView"),
                redirect: { name: 'IncidentIssueListView' },
                children: [
                    { // 故障事件列表
                        path: '', 
                        name: 'IncidentIssueListView', 
                        meta: { is_entrance: false, roles: ['tester', 'admin', 'manager', 'maintenance_assigner', 'maintenance_officer', 'maintenance_receiver', 'maintenance_technician'] , title: "系統設定 / 設備元件 / 瀏覽", icon: "mdi-cog"}, 
                        component: loadView("MaintenanceManagement/MaintenanceManagementView/ConfigurationView/EquipmentComponent/View/IncidentIssueListView"),
                    },
                ]
            },
            //#endregion
        ]

    },

    //#region (固資財產)
    {
        path: '/maintenance-asset-view',
        name: 'MaintenanceAssetView',
        component: loadView("MaintenanceManagement/MaintenanceAssetView"),
        meta: { roles: ['tester', 'admin', 'manager', 'maintenance_assigner', 'maintenance_officer'], title: "固資財產"}, // 需要為 Admin
    },
    //#endregion


    //#region (設備品項)
    {
        path: '/maintenance/equipment/inventory/view',
        name: 'MaintenanceEquipmentInventoryView',
        component: loadView("MaintenanceManagement/MaintenanceEquipmentInventoryView"),
        meta: { 
            keepAlive: true,
            roles: ['tester', 'admin', 'manager', 'maintenance_assigner', 'maintenance_officer', 'team_leader'], 
            title: "設備品項管理"
        }, // 需要為 Admin
    },

    {
        path: '/maintenance/equipment/inventory/view/create',
        name: 'MaintenanceEquipmentInventoryViewForCreate',
        component: loadView("MaintenanceManagement/MaintenanceEquipmentInventory/MaintenanceEquipmentInventoryViewForCreate"),
        meta: { roles: ['tester', 'admin', 'manager', 'maintenance_assigner', 'maintenance_officer', 'team_leader'], title: "新增設備品項管理"}, // 需要為 Admin
    },

    {
        path: '/maintenance/equipment/inventory/view/update/:id',
        name: 'MaintenanceEquipmentInventoryViewForUpdate',
        component: loadView("MaintenanceManagement/MaintenanceEquipmentInventory/MaintenanceEquipmentInventoryViewForUpdate"),
        meta: { roles: ['tester', 'admin', 'manager', 'maintenance_assigner', 'maintenance_officer', 'team_leader'], title: "修改設備品項管理"}, // 需要為 Admin
    },
    //#endregion


    //#region (案場設備)
    {
        path: '/maintenance-device-view',
        name: 'MaintenanceDeviceView',
        component: loadView("MaintenanceManagement/MaintenanceDeviceView"),
        meta: { 
            keepAlive: true,
            roles: ['tester', 'admin', 'manager', 'maintenance_assigner', 'maintenance_officer', 'team_leader'], 
            title: "案場設備管理"
        }, // 需要為 Admin
    },

    {
        path: '/maintenance-device-view/create',
        name: 'MaintenanceDeviceViewForCreate',
        component: loadView("MaintenanceManagement/MaintenanceDeviceView/MaintenanceDeviceViewForCreate"),
        meta: { roles: ['tester', 'admin', 'manager', 'maintenance_assigner', 'maintenance_officer', 'team_leader'], title: "新增案場設備管理"}, // 需要為 Admin
    },

    {
        path: '/maintenance-device-view/update/:id',
        name: 'MaintenanceDeviceViewForUpdate',
        component: loadView("MaintenanceManagement/MaintenanceDeviceView/MaintenanceDeviceViewForUpdate"),
        meta: { roles: ['tester', 'admin', 'manager', 'maintenance_assigner', 'maintenance_officer', 'team_leader'], title: "修改案場設備管理"}, // 需要為 Admin
    },
    //#endregion


    //#region (案場分配設備)
    {
        path: '/maintenance/equipment/position/inventory-allocate/view',
        name: 'MaintenanceEquipmentPositionInventoryAllocateView',
        component: loadView("MaintenanceManagement/MaintenanceEquipmentPositionInventoryAllocateView"),
        meta: { 
            keepAlive: true,
            roles: ['tester', 'admin', 'manager', 'maintenance_assigner', 'maintenance_officer', 'team_leader'], 
            title: "案場設備管理"
        }, // 需要為 Admin
    },

    {
        path: '/maintenance/equipment/position/inventory-allocate/view/create',
        name: 'MaintenanceEquipmentPositionInventoryAllocateViewForCreate',
        props: true, // 透過 props 傳遞參數
        component: loadView("MaintenanceManagement/MaintenanceEquipmentPositionInventoryAllocateView/MaintenanceEquipmentPositionInventoryAllocateViewForCreate"),
        meta: { roles: ['tester', 'admin', 'manager', 'maintenance_assigner', 'maintenance_officer', 'team_leader'], title: "新增案場設備管理"}, // 需要為 Admin
    },

    {
        path: '/maintenance/equipment/position/inventory-allocate/view/update/:id',
        name: 'MaintenanceEquipmentPositionInventoryAllocateViewForUpdate',
        component: loadView("MaintenanceManagement/MaintenanceEquipmentPositionInventoryAllocateView/MaintenanceEquipmentPositionInventoryAllocateViewForUpdate"),
        meta: { roles: ['tester', 'admin', 'manager', 'maintenance_assigner', 'maintenance_officer', 'team_leader'], title: "修改案場設備管理"}, // 需要為 Admin
    },
    //#endregion

];