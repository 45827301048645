import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "@/store";
import { loadView } from "@/router/loadView"

Vue.use(VueRouter)

// 封包
import FeedRecordRoutes from '@/router/config/feedRecordRoutes'
import ExternalProductRoutes from '@/router/config/ExternalProductRoutes'
import AnnouncementRoutes from '@/router/config/AnnouncementRoutes'
import WaterRecodForSeedRoutes from '@/router/config/WaterRecodForSeedRoutes'
import BreedingManagementRoutes from '@/router/config/breedingManagementRoutes'
import OperatationManagementRoutes from '@/router/config/operatationManagementRoutes'
import ReportManagementRoutes from '@/router/config/reportManagementRoutes'
import FoodProcessingRoutes from '@/router/config/foodProcessingRoutes'
import VirusRoutes from '@/router/config/virusRoutes'
import SproutManagementRoutes from '@/router/config/sproutManagementRoutes'
import StationMapRoutes from '@/router/config/stationMapRoutes'
import OthersRoutes from '@/router/config/othersRoutes'
import BonusManagement from '@/router/config/bonusManagement'
import TeamManagementRoutes from '@/router/config/teamManagementRoutes'
import SystemManagementRoutes from '@/router/config/systemManagementRoutes'
import hydrologyManagementRoutes from '@/router/config/hydrologyManagementRoutes'
import historyTransferManagementRoutes from '@/router/config/historyTransferManagementRoutes'
import MaintenanceManagementRoutes from '@/router/config/MaintenanceManagementRoutes'
import TransferSellForEnergyRoutes from '@/router/config/TransferSellForEnergyRoutes'
import LocationManagementRoutes from '@/router/config/LocationManagementRoutes'
import HomeRoutes from '@/router/config/HomeRoutes'

const routes = [
  {
    path: '/auth',
    name: 'AuthLayout', // 添加名稱
    component: () => import('@/layouts/AuthLayout.vue'),
    children: [
      {
        path: 'login',
        name: "LoginView",
        component: () => import('@/views/Auth/LoginView.vue'),
        meta: {
          title: "登入",
          requiresAuth: false, // 不需要驗證
          redirectIfAuth: true, // 如果已經驗證過，則重定向
        }
      },
      {
        path: 'forgot-password',
        name: "ForgotPassword",
        component: () => import('@/views/Auth/ForgotPassword.vue'),
        meta: {
          title: "忘記密碼",
          requiresAuth: false, // 不需要驗證
          redirectIfAuth: true, // 如果已經驗證過，則重定向
        }
      },
      {
        path: 'line-callback',
        name: 'LineCallback',
        component: () => import(/* webpackChunkName: "about" */ '@/views/Auth/LineLogin/LineCallback.vue'),
        meta: {
          title: "Line授權",
          requiresAuth: false, // 不需要驗證
        }
      },
    ]
  },

  {
    path: '/',
    name: 'MainLayout', // 添加名稱
    component: () => import('@/layouts/MainLayout.vue'),
    redirect: { name: "Home" },
    children: [
      { // 首頁
        path: '/f',
        name: 'Home',
        component: loadView("Home"), // 非同步載入: 有需要時才載入元件(lazy-loading)
        meta: {
          is_menu: true,
          title: '首頁',
          icon: 'home',
        }
      },
      

      { /* 用戶資訊處理 */
        path: '/my/profile',
        name: 'UserProfile',
        component: loadView("Auth/User/UserProfile"),
      },

      { /* 用戶資訊處理(修改資訊) */
        path: '/my/profile/update',
        name: 'UserProfileForUpdate',
        component: loadView("Auth/User/UserProfile/UserProfileForUpdate"),
      },

      { /* 修改密碼 */
        path: '/my/profile/change-password',
        name: 'UserProfileForChangePassword',
        component: loadView("Auth/User/UserProfile/UserProfileForChangePassword"),
      },


      { /* 註冊功能 */
        path: '/user/register',
        name: 'UserRegister',
        component: loadView("Auth/User/UserRegister"),
        meta: { roles: ['admin', 'manager', 'team_leader', 'editor', 'accountant', 'marketing', 'data_controller'] } // 需要為 Admin
      },

      { /* 7️⃣JNC資料 */
        path: '/jnc-demo',
        name: 'JNCDemo',
        component: loadView("Others/JNC/JNCDemo"),
        meta: { roles: ['admin'] } // 需要為 Admin
      },

      { // 8️⃣M10太陽能板測試
        path: '/m10-panel',
        name: 'M10_PanelView',
        component: loadView("Others/M10_Panel/M10_PanelView"),
        meta: { roles: ['admin'] } // 需要為 Admin
      },


      // 外部產品
      ...ExternalProductRoutes,

      // 公告
      ...AnnouncementRoutes,

      // 案場水質
      ...WaterRecodForSeedRoutes,

      // 1️⃣(養殖紀錄 - FeedRecord)
      ...FeedRecordRoutes,

      // 2️⃣(養殖管理 - BreedingManagement)
      ...BreedingManagementRoutes,

      // 3️⃣(營運管理 - OperatationManagement)
      ...OperatationManagementRoutes,

      // 4️⃣(報表管理 - ReportManagment)
      ...ReportManagementRoutes,


      // 4️⃣(獎金管理 - BonusManagement)
      ...BonusManagement,

      // 5️⃣(竹田食品加工 - FoodProcessing)
      ...FoodProcessingRoutes,

      // 6️⃣(病毒檢驗)
      ...VirusRoutes,


      // 5️⃣(分組管理 - GroupManagement)
      ...TeamManagementRoutes,

      // 5️⃣(系統管理 - SystemManagement)
      ...SystemManagementRoutes,

      // 5️⃣(轉移數據 - historyTransferManagement)
      ...historyTransferManagementRoutes,

      // 5️⃣(維護管理 - MaintenanceManagement)
      ...MaintenanceManagementRoutes,
      // 經濟規劃書(附屬綠能)
      ...TransferSellForEnergyRoutes,

      

      // 9️⃣(蝦苗管理 - SproutManagement)
      ...SproutManagementRoutes,

      // 🔟(場域地圖 - StationMap)
      ...StationMapRoutes,

      // 🔟(水文地圖 - hydrologyManagementRoutes)
      ...hydrologyManagementRoutes,

      // ❤️(其他路由 - Other)
      ...OthersRoutes,

      // ❤️(區域管理)
      ...LocationManagementRoutes,

      ...HomeRoutes,

      
    ]
  },

  /* 把 404 Page放置到最下面(*) */
  {
    path: '*',
    name: 'NotFoundPage',
    component: loadView("NotFoundPage"),
  },


]

// 解決重複點擊路由所報錯的BUG
// 【資料來源】https://blog.juanertu.com/archives/721f45a5
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}

const router = new VueRouter({
  mode: "history",
  routes
})


router.beforeEach(async (to, from, next) => {
  //#region (用戶資訊捕捉)
  // 是否為登入狀態
  const isLoggedIn = store.getters['auth/isLoggedIn'];
  
  // 判斷使用者資訊是否載入
  const isUserLoaded = store.getters['auth/isUserLoaded'];
  
  // [登入狀態下] 如果 Vuex 裡沒有用戶資訊，嘗試獲取
  if (isLoggedIn && !isUserLoaded) {
    await store.dispatch("auth/fetchUserInfo");
  }

  const { user_info } = store.state['auth']; // 獲取登入後的使用者狀態
  const user_role = user_info?.role || null; // 當前登入用戶的角色(字串)
  const user_roles = user_info?.roles || null; // 當前登入用戶的角色(陣列)
  //#endregion

  //#region (路由資訊捕捉)
  const { meta } = to
  const meta_roles = meta?.roles || null; // 目標路由的角色限制
  const redirectIfAuth = meta?.redirectIfAuth || false; // 如果登入後，是否需要重定向 (登入頁面)
  const requiresAuth = meta?.requiresAuth !== false; // 是否需要登入驗證
  //#endregion

  //#region (是否登入的驗證)
  // 如果已經登入，並且需要重定向到首頁 => 導引向首頁
  if(isLoggedIn && redirectIfAuth) {
    next({ name: "Home" })
  }

  // 如果沒有登入，並且需要驗證 => 導引向登入頁面
  if(requiresAuth && !isLoggedIn) {
    next({ name: "LoginView" })
  }
  //#endregion


  //#region (權限角色驗證)
  // 權限角色驗證
  if (meta_roles) {
    // 檢查使用者角色是否符合路由的角色限制
    const hasAccess = meta_roles.some(
      (role) =>
        user_roles.includes(role)
        || user_role === role
    );

    // 如果驗證不過就回到首頁
    if (!hasAccess) {
      return next({ name: 'Home' });
    }
  }
  //#endregion

  next() // 如果以上條件都通過，則允許直接前往該路由
})
export default router
