<template>
  <v-app>
    <!-- 加載畫面 -->
    <loading-screen 
      v-if="!removed" 
      :is-active="!appReady" 
      :auto-destroy="true"
      app-name="MyApp"
      message="正在載入，請稍候..."
      @destroyed="removed = true"
    />

      <!-- 應用已加載完成狀態 -->
      <router-view />

      <!-- 回頂部的按鈕: 只要頁面往下滑動則會浮出按鈕(手機板不要有 tooltip效果) -->
      <v-tooltip 
        top color="blue-grey darken-4"
        :disabled = "is_mobile">
        <template v-slot:activator="{ on, attrs }">
            <div v-scroll-to="{ el: '#app', offset: -65}" 
                class="goTop d-flex justify-center align-center"
                :class="isTop ? 'goTopAfter' : ''" 
                v-bind="attrs"
                v-on="on">
                <v-icon color="white">mdi-arrow-up</v-icon>
            </div>
        </template>
          <span>回頂部</span>
      </v-tooltip>
  </v-app>
</template>

<script>
import LoadingScreen from './layouts/LoadingScreen.vue'
import { mapGetters, mapState } from 'vuex'
export default {
  name: 'App',
  components: {
    LoadingScreen
  },
  data() {
    return {
      appReady: false,
      removed: false,  // 控制加載組件是否從DOM移除

      //#region (回頂部的按鈕)
      scrollNum: 0, //滾動距離
      isTop: false, //是否顯示回到頂部按鈕
      //#endregion
    }
  },
  computed: {
  },

  created() {
    // 監聽路由變化，確保路由已解析
    const unwatch = this.$watch('$route', () => {
      // 延遲一小段時間確保佈局組件已載入
      setTimeout(() => {
        this.appReady = true
        unwatch()  // 取消監聽，只需要在初始化時執行一次
      }, 300)
    }, { immediate: true })
    
    // 安全機制：確保即使路由沒有變化，應用也會最終載入
    setTimeout(() => {
      this.appReady = true
    }, 800)
  },


  async mounted(){
    // scroll btn 出現(只要移動高度超過 200)
    /* https://iter01.com/548137.html */
    window.addEventListener("scroll", () => {
        let top =
            document.documentElement.scrollTop ||
            document.body.scrollTop ||
            window.pageYOffset;
        this.scrollNum = top;
        if (top >= 200) {
            this.isTop = true;
        } else {
            this.isTop = false;
        }
    });
  }

}
</script>


<style lang="scss">
@import url('./assets/scss/styles.scss');
.app-loading {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5; /* 可以設計成與您的 AuthLayout 背景相符 */
  z-index: 9999;
}

/* 回頂部按鈕樣式設計 */
.goTop {
  position: fixed;
  bottom: -200px;
  right: 5%;
  width: 60px;
  height: 60px;
  border-radius: 30px;
  z-index: 10;
  background-color: #57606f;
  transition: 0.3s ease-in-out;
  font-size: 30px;
  text-align: center;
  line-height: 60px;
  transition: 0.3s ease-in-out;
  cursor: pointer;
}

.goTop:hover {
  background-color: #2c3e50;
  transition: 0.3s ease-in-out;
}
.goTopAfter {
  transition: 0.3s ease-in-out;
  bottom: 1%;
}

/* 手機介面下，下面一點 */
@media screen and (max-width: 577px) {
  .goTop{
    width: 50px;
    height: 50px;
    font-size: 12px;
  }

  .goTopAfter {
    bottom: 2.5%;
  }
}

.app-container{
  background-color: #eee;
  min-height: 400px;
}

/* 所有 View的 container */
.main-container{
  flex: 1;
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 15vh);
}




</style>