import CryptoJS from 'crypto-js' // 引入來進行加密/解密動作
import Swal from 'sweetalert2';
import { AuthTokenService } from '@/api/services'
import { apiGetCurrentUser } from '@/api/modules/system/user'
const getDefaultState = () => { // state
    return {
        // token配置: 如果localStorage有則用，無則給予空val的Object
        authTokens: {
            'refresh': localStorage.getItem('authTokenRefresh'),
            'access': localStorage.getItem('authTokenAccess'),
        } || {'refresh':null,'access':null},

        user_info: null
        // user_info: localStorage.getItem('user_info') 
        //             ? decrypt(localStorage.getItem('user_info'))  // 讀取資料時，記得要解密
        //             : null
    }
}

// 加密資料
function encrypt(data) {
    const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(data), process.env.VUE_APP_CRYPTO_KEY).toString();
    return encryptedData;
}

// 解密資料
function decrypt(data) {
    try {
        const decryptedData = CryptoJS.AES.decrypt(data, process.env.VUE_APP_CRYPTO_KEY).toString(CryptoJS.enc.Utf8);


        return JSON.parse(decryptedData);
    } 
    catch(err) {
        const { message } = err;
        // if (message === 'Malformed UTF-8 data') { // 可能因為 Secret Key改變時，導致解碼錯誤，此時就清空 localStorage
        //     console.error('Malformed UTF-8 data encountered. Clearing localStorage...');
        //     localStorage.clear(); // 清除localStorage
        // }
        localStorage.clear(); // 清除localStorage

        return null;
    }
}

export default {
    namespaced: true,
    state: getDefaultState(),
    getters: {
        isLoggedIn(state) { // 判斷是否登入
            return !!state.authTokens.access // 如果有access token就代表登入了
        },
        isUserLoaded(state) { // 判斷使用者資訊是否載入
            return !!state.user_info // 如果有user資訊就代表載入了
        }
     },
    mutations: { 
       setToken( state, new_token ){
            state.authTokens = new_token
            localStorage.setItem( "authTokenRefresh", new_token['refresh'] )
            localStorage.setItem( "authTokenAccess", new_token['access'] )
       },

       delToken(state){
            state.authTokens = {'refresh':null,'access':null}
            localStorage.removeItem("authTokenRefresh")
            localStorage.removeItem("authTokenAccess")
       },

       setUser(state, new_user){
            state.user_info = new_user
            localStorage.setItem('user_info', encrypt(new_user)); // 將加密後的結果傳入到 localStorage當中
       },
       delUser(state){
            state.user_info = null
            localStorage.removeItem("user_info")
       }
     },
    actions: { 
        // 刷新 Access TOKEN
        async refreshToken({state, commit}, payload){ 
            const refreshToken = localStorage.getItem('authTokenRefresh') || null
            // 刷新Token(當 Access Token過期時)
            try{
                const response = await AuthTokenService.refresh_token(refreshToken)
                if( response.status === 200 ){ // 成功後將 token載入到 VueX中
                    await commit('setToken', response.data)
                }
            }
            
            catch(err){ 
                // 重新登入: 刪除vuex的 token與 user資訊
                await commit('delToken')  
                await commit('delUser') 

                // 如果失敗代表Refresh Token過期了，無法再進行更新(必須重新登入)
                const swal = await Swal.fire({
                    title: "權限過期", 
                    // text: "請重新進入該頁面", 
                    icon: "error",
                    confirmButtonText: "重新登入",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                })
        
                if (swal.isConfirmed){
                    window.location.href = "/login";
                }

            }
        },


        // 判斷 TOKEN狀況
        async handleToken({state, commit, dispatch}, payload){
            /* JWT Token刷新 */
            const accessToken = localStorage.getItem('authTokenAccess') || null
            const refreshToken = localStorage.getItem('authTokenRefresh') || null
            const user_info = localStorage.getItem('user_info') || null
            // 驗證 Token有無效
            try{
                const response = await AuthTokenService.verify_token(accessToken) // 將access token載入來驗證是否過期或無效
                if( response.status === 200 ){
                    if( !user_info || !refreshToken ){ // 避免亂刪localStorage的情況
                        commit('delToken') 
                        commit('delUser') 
                    }
                }
             
            }catch(err){
                // console.log("驗證Token發生錯誤:" + err);
                await dispatch("refreshToken")
            }
        },


        async refreshUser({state, commit, dispatch}, payload){ // 刷新使用者資訊
            commit('delUser') // 先刪除
            const user_info = {
                user_id: payload['id'],
                name: payload['name'],
                profile: payload['profile'],
                is_staff: payload['is_staff'],
                role: payload['groups'] ? payload['groups'][0]['name'] : null,
                roles: payload['groups'] ? payload['groups'].map(item => item['name']) : [],
                role_names: payload['groups'] ? payload['groups'].map(item => item['profile']['role_name']) : [],
            }
            commit('setUser', user_info) // 再新增
        },

        // 取得使用者資訊
        async fetchUserInfo({state, commit, dispatch}, payload){ 
            // 驗證 Token有無效
            try{
                const response = await apiGetCurrentUser()
              if(response.status === 200){
                  const response_data = response['data']
                  const user_info = {
                    ...response_data,
                    role: response_data['groups'] ? response_data['groups'][0]['name'] : null,
                    roles: response_data['groups'] ? response_data['groups'].map(item => item['name']) : [],
                    role_names: response_data['groups'] ? response_data['groups'].map(item => item['profile']['role_name']) : [],
                  }
                  commit('setUser', user_info) // 將使用者資訊存入到 Vuex中
              }
             
            }
            catch(err){
                console.log("取得使用者資訊發生錯誤:" + err);
                // await dispatch("refreshToken")
            }
        }
    },
}