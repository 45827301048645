import Vue from 'vue'
import Vuex from 'vuex'
import module_auth from "./modules/auth"
import module_daily_report from "./modules/daily_report"
import module_external_product from "./modules/external_product"
import module_seed_management from "./modules/seed_management"
import module_food_taste from "./modules/food_taste"
import module_food_stuff from "./modules/food_stuff"
import food_inspect from "./modules/food_inspect"
import external_product from "./modules/product/external_product"
import processed_product from "./modules/product/processed_product"
import material from "./modules/material"
import maintenance from "./modules/maintenance"
import maintenance_apply from "./modules/maintenance/apply"
import maintenance_supplement from "./modules/maintenance/supplement"
import transfer_sell from './modules/operation/transfer_sell'
import transfer_sell_for_energy from './modules/operation/transfer_sell_for_energy'
import food_management from './modules/foods/food_management'


Vue.use(Vuex)

// State
const getDefaultState = () => {
  return {
    is_login: false,
  }
}

export default new Vuex.Store({
  modules: { // modules
      auth: module_auth,
      daily_report: module_daily_report,
      // external_product: module_external_product,
      food_inspect: food_inspect,
      external_product: external_product,
      processed_product: processed_product,
      seed_management: module_seed_management,
      food_taste: module_food_taste,
      food_stuff: module_food_stuff,
      material: material,
      maintenance: maintenance,
      maintenance_apply: maintenance_apply,
      maintenance_supplement: maintenance_supplement,
      transfer_sell: transfer_sell,
      transfer_sell_for_energy: transfer_sell_for_energy,
      food_management: food_management,
  },
  state: getDefaultState(),
  getters: {

  },
  mutations: {
    setLogin(state, payload){
      state.is_login = payload
    }
  },
  actions: {
  },

})
